<template>
  <div class="fragment">
    <template
        v-if="$store.getters.getExpressProblem.length > 0 && $store.getters.getExpressProblemLoading !== true">
      <div class="site-table-wrap small-table"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate([
            'common_id',
            'common_date',
            'expressProblem_ticket',
            'expressProblem_CompleteByAdmin',
            'common_manage',
          ])"></div>
        <table class="site-table" v-if="!isMobileFunc()">
          <thead>
          <tr>
<!--            <th></th>-->
            <th>{{ $t('common_id.localization_value.value') }}</th>
            <th>Express {{ $t('common_id.localization_value.value') }}</th>
            <th>{{ $t('common_date.localization_value.value') }}</th>
            <th v-if="isAdmin"> {{$t('common_user.localization_value.value')}}</th>
            <th>{{ $t('payments_userBalance.localization_value.value') }}</th>
            <th>{{ $t('userModule_BalancePayoneer.localization_value.value') }}</th>
            <th>{{ $t('poshta_trackingNumber.localization_value.value') }}</th>
            <th width="100%" class="hide-1400"></th>
            <th v-if="isAdmin">{{ $t('common_manage.localization_value.value') }}</th>
          </tr>
          </thead>
          <tbody>

          <tr
              v-for="(item, index) in $store.getters.getExpressProblem" :key="index">
<!--            <td>-->
<!--              <DefaultCheckbox-->
<!--                  class="empty-label"-->
<!--                  :selectedNow="selectedNow"-->
<!--                  :dataValue="item.id"-->
<!--              />-->
<!--            </td>-->
            <td>
              #{{ item.id }}
            </td>
            <td>
              #{{ item.order_express_id }}
            </td>
            <td>
              {{ item.created_at | moment("DD MMM, YYYY") }}
            </td>
            <td v-if="isAdmin">
              <TableUserColumn
                  v-if="isAdmin"
                  :item="item.order_express"
              />
            </td>
            <td>
              <ValueHelper
                  :value="item"
                  :deep="'order_express.user.balance'"
              />
            </td>
            <td>
              <ValueHelper
                  :value="getUserPayoneerBalance(item.order_express.user)"
              />
            </td>
            <td>
              <ValueHelper
                  :value="item"
                  :deep="'order_express.tracking_number'"
              />
            </td>
<!--            <td>-->
<!--              <ValueHelper-->
<!--                  :value="item"-->
<!--                  :deep="'payment_transaction.shop.shop_name'"-->
<!--              />-->
<!--            </td>-->
<!--            <td>-->
<!--              {{ item.keyword }}-->
<!--            </td>-->
            <td class="hide-1400"></td>
            <td class="p-0">
              <div class="table-btn d-flex">
                <MainButton
                    class="mr-2"
                    :value="$t('expressProblem_ticket.localization_value.value')"
                    @click.native="openPopup(item)"
                />
                <MainButton
                    class="mr-2"
                    :value="$t('expressProblem_CompleteByAdmin.localization_value.value')"
                    @click.native="completeAdmin(item.id)"
                />

                <MainButton
                    class="mr-2"
                    :value="$t('common_delete.localization_value.value')"
                    @click.native="deleteItem(item.id)"
                />
              </div>
            </td>
          </tr>
          </tbody>
        </table>

        <div class="table-card" v-if="isMobileFunc()">
          <div class="table-card__list">
            <div class="table-card__item"
                 v-for="(item, index) in $store.getters.getExpressProblem"
                 :key="index">
              <ExpressProblemTableMobile
                  :item="item"
                  @openPopup="openPopup(item)"
                  @completeAdmin="completeAdmin(item.id)"
                  @deleteItem="deleteItem(item.id)"
              />
            </div>
          </div>
        </div>

        <div class="table-bottom-btn">
          <div class="table-bottom-btn__inner">
            <ShowMore
                v-if="$store.getters.getExpressProblemCommonList.next_page_url !== null && $store.getters.getExpressProblem.length > 0"
                @click.native="$emit('showMore')"
                v-bind:class="{'disabled-btn' : $store.getters.getNextExpressProblemPage}"
                :count="$store.getters.getExpressProblemCommonList.total - $store.getters.getExpressProblemForPage * $store.getters.getExpressProblemCommonList.current_page < $store.getters.getExpressProblemForPage ?
                    $store.getters.getExpressProblemCommonList.total - $store.getters.getExpressProblemForPage * $store.getters.getExpressProblemCommonList.current_page:
                    $store.getters.getExpressProblemForPage"
            />

<!--            <ExportBtn-->
<!--                class="table-bottom-btn__right"-->
<!--                @downloadFiles="type => $emit('downloadFiles', type)"-->
<!--            />-->
          </div>
        </div>


      </div>
    </template>

    <template
        v-if="$store.getters.getExpressProblemLoading === false && $store.getters.getExpressProblem.length === 0">
      <NoResult
          :countFilterParams="countFilterParams"
          :title="$t('common_noDataYet.localization_value.value')"
          :titleWithFilter="$t('common_noResult.localization_value.value')"
          :text="$t('common_noDataYetTxt.localization_value.value')"
          :textWithFilter="$t('common_noResultSeems.localization_value.value')"
          @resetFilter="$emit('resetFilter')"
      />
    </template>

<!--    <InfoPopup-->
<!--        v-if="isModalInfoPopup"-->
<!--        :itemId="itemId"-->
<!--        @reload="$emit('reload')"-->
<!--        @approve="$emit('approve', itemId)"-->
<!--        @refund="$emit('refund', itemId)"-->
<!--        @close="$emit('changeInfoPopup', false)"-->
<!--    />-->
  </div>
</template>

<script>
import ShowMore from "../../../../../../coreComponents/Buttons/ShowMore/ShowMore";
import {mixinDetictingMobile} from '../../../../../../../mixins/mobileFunctions'
// import InfoPopup from "../../../../popups/InfoPopup/InfoPopup";
import MainButton from "../../../../../../UI/buttons/MainButton/MainButton";
import NoResult from "../../../../../../coreComponents/NoResult/NoResult";
import ExpressProblemTableMobile from "./ExpressProblemTableMobile/ExpressProblemTableMobile";
import TableUserColumn from "../../../../../../coreComponents/TableComponents/TableUserColumn/TableUserColumn";
import ValueHelper from "../../../../../../coreComponents/ValueHelper/ValueHelper";
// import DefaultCheckbox from "../../../../../../UI/checkboxes/DefaultCheckbox/DefaultCheckbox";
import {routeFilter} from "../../../../../../../mixins/routeFilterMixins/routeFilter";
// import ExportBtn from '../../../../../../coreComponents/Buttons/Export/Export'

export default {
  name: "ExpressProblemTable",
  components: {
    // ExportBtn,
    ValueHelper,
    // DefaultCheckbox,
    TableUserColumn,
    ExpressProblemTableMobile,
    NoResult,
    ShowMore,
    // InfoPopup,
    MainButton,
  },

  mixins: [mixinDetictingMobile, routeFilter],

  props: {
    countFilterParams: Number,
    selectedNow: Boolean,
    isModalInfoPopup: {
      type: Boolean,
      default: false,
    },
    itemId: Number,
  },

  data() {
    return {
      showFilter: false,
      isMobile: false,

      showContent: false,

      // isModalProductsToWarehouse: false,

      show1: false,
      show2: false,

    }
  },

  methods: {

    openPopup(item) {
      let text = {
        title: 'expressProblem_ticket',
        customTxt: item.ticket_text,
        txt: '',
        yes: 'common_copy',
        no: 'common_close'
      }

      let confirm = () => {
        this.copyToClipboard(item.ticket_text)
        return true
      }

      this.deleteFunc(text, confirm)
    },

    completeAdmin(id) {

      let text = {
        title: 'common_AreYouSure',
        txt: '',
        yes: 'common_confirm',
        no: 'common_confirmNo'
      }

      let confirm = () => {
        this.$store.dispatch('competeByAdminExpressProblem', id).then(response => {

          if (this._.has(response, 'data') && response.data.status === this.$store.getters.GET_ERRORS.SUCCESS_CODE) {
            this.openNotify('success', 'common_notificationPasswordChanged')
            this.$emit('reload')
          } else {
            this.openNotify('error', 'common_notificationUndefinedError')
          }
        })

        this.$store.commit('setConfirmDeletePopup', false)
        return true
      }

      this.deleteFunc(text, confirm)
    },

    deleteItem(id) {
      let text = {
        title: 'common_deleteConfirm',
        txt: '',
        yes: 'common_confirm',
        no: 'common_confirmNo'
      }

      let confirm = () => {
        this.$store.dispatch('deleteExpressProblem', id).then(response => {
          if (this._.has(response, 'data') && response.data.status === this.$store.getters.GET_ERRORS.SUCCESS_CODE) {
            this.openNotify('success', 'common_notificationRecordDeleted')
            this.$emit('reload')
          } else {
            this.openNotify('error', 'common_notificationUndefinedError')
          }
        })

        this.$store.commit('setConfirmDeletePopup', false)
        return true
      }

      this.deleteFunc(text, confirm)
    },


  }
}
</script>

<style scoped lang="scss">
.comment-row {
  overflow: hidden;
  text-overflow: ellipsis;
  width: 115px;
  position: relative;
  display: inline-block;
  white-space: nowrap;
}

@media (max-width: 1400px) {
  .comment-row {
    width: 100px;
  }
}

.table-manage-list .manager-btn {
  width: 200px;
}

@media (max-width: 1400px) {
  .table-manage-list .manager-btn {
    width: 100px;
  }

  .user-field-admin {
    flex-wrap: wrap;
    display: flex;
  }

  .hide-1400 {
    display: none;
  }
}

/*.site-table thead th:first-child,*/
/*.site-table tbody td:first-child{*/
/*padding-right: 0;*/
/*}*/

/*.site-table thead th:nth-child(2),*/
/*.site-table tbody td:nth-child(2){*/
/*padding-left: 0;*/
/*}*/

/*.site-table thead th:last-child,*/
/*.site-table tbody td:last-child{*/
/*padding-right: 0;*/
/*}*/

.small-table th,
.small-table td {
  white-space: nowrap;
}

.size-24 {
  width: 24px;
  height: 24px;
  margin-top: -2px;
}


</style>
