<template>
  <div class="fragment">
    <ExpressProblemTableSection
            :countFilterParams="countFilterParams"
            :filterGetParams="filterGetParams"
            :isModalInfoPopup="isModalInfoPopup"
            :itemId="itemId"
            @resetFilter="resetFilter"
            @showMore="showMore"
            @changeFilter="changeFilter"
            @reload="reload"
            @downloadFiles="downloadFiles"
    />
  </div>

</template>

<script>
  import ExpressProblemTableSection from "./ExpressProblemTableSection/ExpressProblemTableSection";
  import {routeFilter} from "../../../../../mixins/routeFilterMixins/routeFilter";

  export default {
    name: "ExpressProblemTable",
    components: {
      // ExpressProblemTableAdmin,
      ExpressProblemTableSection,
    },

    mixins: [routeFilter],

    data(){
      return {
        forPage: this.$store.getters.getExpressProblemForPage,
        page: 1,
        onePage: 1,
        filterDate: [],
        filterUserName: '',
        filterId: '',
        filterUserId: '',
        // filterTransactionNumber: '',
        filterExpressId: '',
        filterTrackingNumber: '',
        // filterZip: '',
        // filterContactName: '',
        // filterEmail: '',
        // filterTotalAmount: '',
        // filterTransactionKeyword: '',
        // filterShopName: '',

        countFilterParams: 0,
        filterGetParams: {},

        isModalInfoPopup: false,
        itemId: -1,
      }
    },

    mounted() {
      this.filter()
    },


    methods: {

      getFilterParam() {
        this.filterGetParams = {
          page: this.$route.query.page,
          count: this.$route.query.count,
          // date: this.$route.query.date,
          userName: this.$route.query.userName,
          userId: this.$route.query.userId,

          id: this.$route.query.id,
          expressId: this.$route.query.expressId,
          trackingNumber: this.$route.query.trackingNumber,
        }
      },

      refreshTable() {
        this.filter()
      },

      filter(next = false) {
        this.getFilterParam()

        let url

        // generate main filter if has get params in URL
        let generateMainFilter = this.generateMainFilterFromUrl(next, this)

        // generate other filter if has get params in URL
        this.generateParamsFilterFromUrl(this)

        // Generate filter URL
        url = this.generateFilterUrl(
          generateMainFilter.page,
          generateMainFilter.forPage
        )

        if (next) this.$store.commit('setNextExpressProblemPage', true)
        this.$store.dispatch('fetchExpressProblem', url).then(() => {
          this.$store.commit('setNextExpressProblemPage', false)
          this.$store.commit('setGlobalUpdateTable', false)
        })


        this.checkCountFilter(['userName'])
      },

      generateFilterUrl(page, forPage) {
        let myQuery = this.createQueryBuilderObject({withUserNewMark: true})

        this.generateFilterQueryParams(
          myQuery,
          {
            // filterId: 'ForbiddenPaymentTransactionInstanceId',
            filterUserId: 'user_id',
            filterExpressId: 'express_order_id',
            filterTrackingNumber: 'tracking_number',
          },
        )

        if (this.filterDate.length > 0) {
          let date = this.generateDateForFilter(this.filterDate, 'YYYY-MM-DD')

          myQuery.whereIn('between_created_at', date)
        }

        return this.generateGetParamsFromQuery(myQuery, forPage, page)


      },

      downloadFiles(type) {
        let forPage, page, url, exportIds

        exportIds = this.getCheckedRows('row-name')

        // generate main filter if has get params in URL
        let generateMainFilter = this.generateMainFilterFromUrl(false, this)

        page = generateMainFilter.page
        forPage = generateMainFilter.forPage

        url = this.generateFilterUrl(page, forPage, exportIds)

        this.$store.dispatch('getExportExpressProblem', {filter: url, exportType: type}).then((response) => {
          if (this._.has(response, 'data') && response.status === this.$store.getters.GET_ERRORS.SUCCESS_CODE) {
            const blob = new Blob([response.data], {type: `application/${type}`})
            const link = document.createElement('a')
            link.href = URL.createObjectURL(blob)
            link.download = `Forbidden goods.${type}`
            link.click()
            URL.revokeObjectURL(link.href)
          } else {
            setTimeout(() => {
              this.openNotify('error', 'common_notificationUndefinedError')
            }, 200)
          }
        }).catch(error => this.createErrorLog(error))
      },

    }

  }
</script>

<style scoped>

</style>
