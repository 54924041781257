<template>
  <div class="table-card__item-content"
       :class="{show: show}"
  >
    <div class="table-card__item-head">
      <div class="table-card__item-head-row d-flex">
        #{{item.id}}
      </div>
      <div class="table-card__item-head-row">
        <div class="table-card__item-head-txt">
          {{ item.created_at | moment("DD MMM, YYYY") }}
        </div>
      </div>
    </div>
    <transition name="slide">
      <div class="table-card__item-body"
           v-if="show"
      >
        <div class="table-card__item-row custom-row">
          <div class="table-card__item-col custom-col custom-col--50">
            <div class="table-card__item-label">
              {{ $t('common_date.localization_value.value') }}
            </div>
            <div class="table-card__item-info">
              {{ item.created_at | moment("DD MMM, YYYY") }}
            </div>
          </div>
          <div class="table-card__item-col custom-col">
            <div class="table-card__item-label">
              Express {{ $t('common_id.localization_value.value') }}
            </div>
            <div class="table-card__item-info">
              <ValueHelper
                  :value="item"
                  :deep="'order_express_id'"
              />
            </div>
          </div>
          <div class="table-card__item-col custom-col">
            <div class="table-card__item-label">
              {{ $t('common_user.localization_value.value') }}
            </div>
            <div class="table-card__item-info">
              <TableUserColumn
                  v-if="isAdmin"
                  :item="item.order_express"
              />
            </div>
          </div>
          <div class="table-card__item-col custom-col">
            <div class="table-card__item-label">
              {{ $t('payments_userBalance.localization_value.value') }}
            </div>
            <div class="table-card__item-info">
              <ValueHelper
                  :value="item"
                  :deep="'order_express.user.balance'"
              />
            </div>
          </div>
          <div class="table-card__item-col custom-col">
            <div class="table-card__item-label">
              {{ $t('userModule_BalancePayoneer.localization_value.value') }}
            </div>
            <div class="table-card__item-info">
              <ValueHelper
                  :value="getUserPayoneerBalance(item.order_express.user)"
              />
            </div>
          </div>
          <div class="table-card__item-col custom-col">
            <div class="table-card__item-label">
              Express {{ $t('poshta_trackingNumber.localization_value.value') }}
            </div>
            <div class="table-card__item-info">
              <ValueHelper
                  :value="item"
                  :deep="'order_express.tracking_number'"
              />
            </div>
          </div>
        </div>
        <div class="table-card__item-btn" v-if="isAdmin">
          <div class="table-card__item-btn-i table-btn">
            <MainButton
                class="mr-2"
                :value="$t('expressProblem_ticket.localization_value.value')"
                @click.native="$emit('openPopup')"
            />
          </div>
          <div class="table-card__item-btn-i table-btn">
            <MainButton
                class="mr-2"
                v-bind:class="{'disabled-btn' : $store.getters.getExpressProblemBtn}"
                :value="$t('expressProblem_CompleteByAdmin.localization_value.value')"
                @click.native="$emit('completeAdmin')"
            />
          </div>
          <div class="table-card__item-btn-i table-btn">
            <MainButton
                class="mr-2"
                v-bind:class="{'disabled-btn' : $store.getters.getExpressProblemBtn}"
                :value="$t('common_delete.localization_value.value')"
                @click.native="$emit('deleteItem')"
            />
          </div>
        </div>
      </div>
    </transition>
    <div class="table-card__item-footer">
      <div class="table-card__item-show"
           @click="show = !show"
      >
        {{$t('common_showMore.localization_value.value')}}
      </div>
    </div>
  </div>
</template>

<script>
  import MainButton from "../../../../../../../UI/buttons/MainButton/MainButton";
  import ValueHelper from "../../../../../../../coreComponents/ValueHelper/ValueHelper";
  import TableUserColumn from "@/components/coreComponents/TableComponents/TableUserColumn/TableUserColumn";
  export default {
    name: "ExpressProblemTableMobile",
    components: {TableUserColumn, ValueHelper, MainButton},

    props: {
      item: Object,
    },

    data(){
      return {
        show: false,
      }
    }
  }
</script>

<style scoped>

</style>
